<template>
  <v-app class="app">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',

  data: () => ({
    //
  }),

  mounted(){
    
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap');

body{
   background-color: #FF0000;
}

html { 
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.dt-container {
    border-radius: 10px !important;
}
.dt-container thead th {
    background-color: #000000;
    color: white !important;
}
.dt-container thead th:first-child {
    border-radius: 10px 0 0 0;
}

.dt-container thead th:last-child {
    border-radius: 0 10px 0 0;
}

.dt-container tr:nth-child(even) {
   background-color: #f6f6f6;
}


/* ::-webkit-scrollbar {
  width: 8px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: '#F5F5F5';
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: '#F5F5F5';
  border-radius: 10px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: '#F5F5F5';
} */

@keyframes fadeIn {
    0%{
       opacity: 0;
       transform: translateZ(0);
    }
    100%{
       opacity: 1;
       transform: translateZ(100%)
    }
}

@keyframes viewUp {
    0%{
       opacity: 0;
       transform: translateY(0);
    }
    50%{
       opacity: 0.5;
       transform: translateY(12px)
    }
    100%{
       opacity: 1;
       transform: translateY(0)
    }
}

.doughnut-style {
    width:110px;
    height:110px;
    margin: 7px;
}

.toggle{
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.animated{
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

@media (max-width: 960px) {
  .v-data-table-headers--mobile {
    display: none;
  }
}
</style>