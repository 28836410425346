<template>
  <v-container class="toggle animated" fluid>
    <v-row dense>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="search"
          variant="outlined"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          density="comfortable"
          solo
          hide-details
          class="rounded-lg"
          color="white"
          style="font-family: Saira Condensed, sans-serif"
        ></v-text-field>
      </v-col>
      <v-col class="text-right" cols="12" md="8">
        <v-btn
          @click="showAddDialog()"
          prepend-icon="mdi-plus"
          class="mt-2 rounded-lg"
          color="primary"
          height="40px"
          elevation="2"
          style="font-family: Saira Condensed, sans-serif"
        >
          Add Balance
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card class="dt-container mt-2">
          <v-data-table
            :mobile="isMobileOrTablet()"
            :headers="headers"
            :items="gtbData"
            :search="search"
            :loading="loading"
            class="text-subtitle-1"
            style="font-family: Saira Condensed, sans-serif"
          >
            <template v-slot:loading>
              <v-progress-linear
                indeterminate
                color="primary"
                height="5"
                class="mt-1 rounded-lg"
              ></v-progress-linear>
              <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span v-if="item.status == 'active'">
                <v-chip color="blue">Active</v-chip>
              </span>
              <span v-if="item.status == 'done'">
                <v-chip color="gray">Done</v-chip>
              </span>
              <span v-if="item.status == 'started'">
                <v-chip color="green">Started Playing</v-chip>
              </span>
              <span v-if="item.status == 'ongoing'">
                <v-chip color="orange">Ongoing</v-chip>
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ formatDateTime(item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.startingBalance`]="{ item }">
              <span>{{
                item.startingBalance > 0 ? "$" + item.startingBalance : ""
              }}</span>
            </template>
            <template v-slot:[`item.endingBalance`]="{ item }">
              <span>{{
                item.endingBalance > 0 ? "$" + item.endingBalance : ""
              }}</span>
            </template>
            <template v-slot:[`item.profit`]="{ item }">
              <span>{{ item.profit > 0 ? "$" + item.profit : "" }}</span>
            </template>
            <template v-slot:[`item.timerDuration`]="{ item }">
              <span v-if="item.status == 'done'">--:--</span>
              <span v-else>{{ String(timer(item).minutes.value).padStart(2, '0') }} : {{ String(timer(item).seconds.value).padStart(2, '0') }}</span>
            </template>
            <template v-slot:[`item.biggestWin`]="{ item }">
              <span>{{
                item.biggestWin > 0 ? "$" + item.biggestWin : ""
              }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <v-icon v-if="item.status != 'active'"
                  @click="viewBalanceDetail(item)"
                  class="me-2"
                  size="small"
                >
                  mdi-list-box
                </v-icon>
                <v-icon
                  v-if="item.status == 'done'"
                  @click="showEditDialog(item)"
                  class="me-2"
                  size="small"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="item.status == 'done'"
                  @click="showDeleteDialog(item)"
                  class="me-2"
                  size="small"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-if="item.status == 'ongoing' && timer(item).minutes.value == 0 && timer(item).seconds.value == 0"
                  @click="showPlayDialog(item)"
                  class="me-2"
                  size="small"
                >
                  mdi-play-circle
                </v-icon>
                <v-icon
                  v-if="item.status == 'started'"
                  @click="showDoneDialog(item)"
                  class="me-2"
                  size="small"
                >
                  mdi-stop-circle-outline
                </v-icon>
                <v-icon
                  v-if="item.status == 'active'"
                  @click="startTimer(item)"
                  class="me-2"
                  size="small"
                >
                  mdi-timer
                </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog
    v-model="startDialog"
    width="450"
    persistent
    style="font-family: Saira Condensed, sans-serif"
  >
    <v-card color="primary" rounded="lg" class="pa-2">
      <v-card-text class="font-weight-bold">
        Are you sure you want to start receiving guesses?
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn
          @click="startDialog = false"
          :disabled="loadingStart"
          variant="outlined"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loadingStart"
          @click="startGuessing()"
          color="red"
          variant="elevated"
        >
          Confirm
        </v-btn>
      </template>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="playDialog"
    width="450"
    persistent
    style="font-family: Saira Condensed, sans-serif"
  >
    <v-card color="primary" rounded="lg" class="pa-2">
      <v-card-text class="font-weight-bold">
        Are you sure you want to stop receiving guesses and play games?
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn
          @click="playDialog = false"
          :disabled="loadingPlay"
          variant="outlined"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="startPlaying()"
          :loading="loadingPlay"
          color="red"
          variant="elevated"
        >
          Confirm
        </v-btn>
      </template>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="doneDialog"
    width="450"
    persistent
    style="font-family: Saira Condensed, sans-serif"
  >
    <v-card color="primary" rounded="lg" class="pa-2">
      <v-card-text class="font-weight-bold">
        Are you sure you want to stop playing games and set the winners?
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn
          @click="doneDialog = false"
          :disabled="loadingDone"
          variant="outlined"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loadingDone"
          @click="donePlaying()"
          color="red"
          variant="elevated"
        >
          Confirm
        </v-btn>
      </template>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="deleteDialog"
    width="450"
    persistent
    style="font-family: Saira Condensed, sans-serif"
  >
    <v-card color="primary" rounded="lg" class="pa-2">
      <v-card-text class="font-weight-bold">
        Are you sure you want to delete this GTB Session?
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn
          @click="deleteDialog = false"
          :disabled="loadingDelete"
          variant="outlined"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loadingDelete"
          @click="deleteGTB()"
          color="red"
          variant="elevated"
        >
          Confirm
        </v-btn>
      </template>
    </v-card>
  </v-dialog>

  <v-snackbar
    v-model="snackbar"
    :timeout="2500"
    color="secondary"
    location="top"
  >
    <v-icon>mdi-alert-circle</v-icon>
    <span class="ml-2" style="font-family: Saira Condensed, sans-serif">{{
      snackText
    }}</span>
  </v-snackbar>

  <BalanceDialog ref="BalanceDialog" @refresh="init()" />
  <EndingBalanceDialog ref="EndingBalanceDialog" @refresh="init()"/>
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import moment from "moment";
import { useTimer } from "vue-timer-hook";
import BalanceDialog from "../Dialogs/BalanceDialog.vue";
import EndingBalanceDialog from "../Dialogs/EndingBalanceDialog.vue";

export default defineComponent({
  components: {
    BalanceDialog,
    EndingBalanceDialog,
  },
  data() {
    return {
      gtbData: [],
      search: null,
      loading: false,
      headers: [
        {
          title: "Starting Balance",
          align: "start",
          sortable: true,
          key: "startingBalance",
        },
        { title: "Status", align: "start", sortable: true, key: "status" },
        {
          title: "Ending Balance",
          align: "start",
          sortable: true,
          key: "endingBalance",
        },
        // { title: "Profit", align: "start", sortable: true, key: "profit" },
        // {
        //   title: "Biggest Win",
        //   align: "start",
        //   sortable: true,
        //   key: "biggestWin",
        // },
        { title: "Timer", align: "start", sortable: true, key: "timerDuration" },
        {
          title: "Created At",
          align: "start",
          sortable: true,
          key: "createdAt",
        },
        { title: "Actions", align: "end", sortable: false, key: "action" },
      ],

      snackbar: false,
      snackText: null,

      startDialog: false,
      playDialog: false,
      doneDialog: false,
      deleteDialog: false,
      loadingDelete: false,
      loadingDone: false,
      loadingPlay: false,
      itemToUpdate: [],
      itemToDelete: [],
    };
  },
  methods: {
    init() {
      const token = localStorage.getItem("romieinc-admin-accesstoken");
      if (token) {
        this.loading = true;
        axios
          .get(
            process.env.VUE_APP_SERVER_URL + "/api/admin/guess-the-balance",
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
              },
            }
          )
          .then((res) => {
            if (res) {
              this.gtbData = res.data.gtbs;
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.removeItem("vuex");
                localStorage.removeItem("romieinc-admin-accesstoken");
                window.location.reload();
              } else {
                console.log(`Error: ${error.response.status}`);
              }
            } else if (error.request) {
              console.log("No response received", error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      } else {
        localStorage.removeItem("vuex");
        localStorage.removeItem("romieinc-admin-accesstoken");
        window.location.reload();
      }
    },
    timer(data){
      const timer = useTimer(moment(data.timerTimeStarted).utc().add(data.timerDuration, 'minutes'));
      return timer
    },
    showAddDialog() {
      this.$refs.BalanceDialog.initAdd();
    },
    showEditDialog(data) {
      this.$refs.BalanceDialog.initUpdate(data);
    },
    viewBalanceDetail(data) {
      this.$refs.BalanceDialog.initView(data);
    },
    startTimer(data) {
      this.startDialog = true;
      this.itemToUpdate = data;
    },
    showDoneDialog(data){
      this.doneDialog = true
      this.itemToUpdate = data
    },
    donePlaying(){
      this.loadingDone = false
      this.doneDialog = false

      this.$refs.EndingBalanceDialog.initEnd(this.itemToUpdate)
    },
    showPlayDialog(data){
      this.playDialog = true
      this.itemToUpdate = data
    },
    showDeleteDialog(data){
      this.itemToDelete = data
      this.deleteDialog= true
    },
    startGuessing() {
      this.startDialog = false;
      this.loadingStart = true;
      const token = localStorage.getItem("romieinc-admin-accesstoken");
      axios
        .post(
          process.env.VUE_APP_SERVER_URL +
            "/api/admin/guess-the-balance/start-timer",
          {
            gtbId: this.itemToUpdate._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.loadingStart = false;
            this.startDialog = false
            this.init();
            this.snackText = "Guess the Balance Details Updated!";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.removeItem("vuex");
              localStorage.removeItem("romieinc-admin-accesstoken");
              window.location.reload();
            } else if (
              error.response.data.message == "Unable to start GTB timer: No GTP added"
            ) {
              this.snackText = "Unable to start guess in this GTB, must add GTP";
              this.snackbar = true;
              this.loadingStart = false;
              this.startDialog = false
            } else {
              console.log(`Error: ${error.response.status}`);
            }
          } else if (error.request) {
            console.log("No response received", error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
    startPlaying(){
      this.loadingStart = true
      const token = localStorage.getItem("romieinc-admin-accesstoken");
      const formData = {
          id: this.itemToUpdate._id,
          status: 'started'
      }
      axios
          .patch(process.env.VUE_APP_SERVER_URL +"/api/admin/guess-the-balance/update", formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
              },
            }
          )
          .then((res) => {
            if (res) {
              this.loadingStart = false;
              this.playDialog = false
              this.init();
              this.snackText = "Guess the Balance Details Updated!";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log("Error", error.message);
            }
          });
    },
    deleteGTB(){
          this.loadingDelete = true
          const token = localStorage.getItem("romieinc-admin-accesstoken");
          axios.delete(process.env.VUE_APP_SERVER_URL +"/api/admin/guess-the-balance/delete?id=" + this.itemToDelete._id ,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
              },
            }
          )
          .then((res) => {
            if (res) {
              this.loadingDelete = false;
              this.deleteDialog = false
              this.init();
              this.snackText = "Guess the Balance Succesfully Deleted!";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log("Error", error.message);
            }
          });
    },
  },
  mounted() {
    this.init();
  },
});
</script>

<style scoped></style>
