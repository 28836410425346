<template>
  <v-container>
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card
        class="rounded-lg"
        style="display: flex; flex-direction: column; height: 100%"
      >
        <v-card-title
          style="
            background-color: #000000 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
          "
        >
          <div>
            <span
              class="font-weight-bold text-h6"
              style="font-family: Saira Condensed, sans-serif"
              >{{ main_title }}</span
            >
            <div
              class="text-caption"
              style="font-family: Saira Condensed, sans-serif"
            >
              {{ sub_title }}
            </div>
          </div>
          <v-btn
            :disabled="loading"
            flat
            icon
            color="primary"
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px)">
          <v-form ref="form" class="mt-4">
            <v-row dense>
              <v-col v-if="action == 'add'" cols="12" md="6">
                <v-select
                  v-model="gtbId"
                  label="GTB"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                  :items="gtbData"
                  item-title="name"
                  item-value="value"
                  :rules="[formRules.required]"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="action !== 'end'">
                <v-text-field
                  v-model="slotTitle"
                  :rules="[formRules.required]"
                  label="Slot Title"
                  type="text"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="action !== 'end'">
                <v-text-field
                  v-model="bonusBuyCost"
                  :rules="[formRules.required]"
                  label="Bonus Buy Cost"
                  type="number"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                  :readonly="action == 'end'"
                ></v-text-field>
              </v-col>
              <!-- <v-col v-if="action == 'edit'" cols="12" md="6">
                <v-select
                  v-model="status"
                  label="Status"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                  :items="statusTypes"
                  item-title="name"
                  item-value="value"
                  :readonly="action == 'end'"
                ></v-select>
              </v-col> -->
              <v-col
                v-if="action == 'end' && payoutStatus == 'end'"
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="bonusBuyPayout"
                  :rules="[formRules.required]"
                  label="Bonus Buy Payout"
                  type="number"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-3" style="margin-top: auto">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1 text-subtitle-1"
            :disabled="loading"
            text="Cancel"
            variant="outlined"
            elevation="1"
            @click="closeDialog()"
            style="font-family: Saira Condensed, sans-serif"
          ></v-btn>
          <v-btn
            class="mr-1 text-subtitle-1"
            :loading="loading"
            text="Save"
            variant="elevated"
            elevation="1"
            color="primary"
            @click="validateForm()"
            style="font-family: Saira Condensed, sans-serif"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewDialog" width="800" persistent>
      <v-card
        class="rounded-lg"
        style="display: flex; flex-direction: column; height: 100%"
      >
        <v-card-title
          style="
            background-color: #000000 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
          "
        >
          <div>
            <span
              class="font-weight-bold text-h6"
              style="font-family: Saira Condensed, sans-serif"
              >{{ main_title }}</span
            >
            <div
              class="text-caption"
              style="font-family: Saira Condensed, sans-serif"
            >
              {{ sub_title }}
            </div>
          </div>
          <v-btn
            :disabled="loading"
            flat
            icon
            color="primary"
            @click="closeViewDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px)">
          <v-row
            dense
            v-if="viewType == 'gtp'"
            class="w-full"
            style="font-family: Roboto, sans-serif"
          >
            <v-col
              class="text-subtitle-1"
              cols="12"
              style="font-family: Saira Condensed, sans-serif"
            >
              <span class="font-weight-bold">Date:</span>
              {{ formatDate(payoutData.createdAt) }}
            </v-col>
            <!-- <v-col cols="12" class=""> -->
            <v-col
              cols="6"
              class="text-subtitle-1 font-weight-bold"
              style="font-family: Saira Condensed, sans-serif"
              >Slot Name:</v-col
            >
            <v-col
              cols="6"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >{{ payoutData.slotTitle }}</v-col
            >
            <v-col
              cols="6"
              class="font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Bonus Buy Cost:
            </v-col>
            <v-col
              cols="6"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >${{ payoutData.bonusBuyCost ?? 0 }}</v-col
            >
            <v-col
              cols="6"
              class="font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Bonus Buy Payout:
            </v-col>
            <v-col
              cols="6"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >${{ payoutData.bonusBuyPayout ?? 0 }}</v-col
            >
            <v-col
              cols="6"
              class="font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Profit:
            </v-col>
            <v-col
              cols="6"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >${{ payoutData.profit ?? 0 }}</v-col
            >
            <v-col
              cols="6"
              class="font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Winner:
            </v-col>
            <v-col
              cols="6"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >{{
                payoutData.status == "done" ? payoutData?.winner[0].user : ""
              }}</v-col
            >
            <v-col cols="12">
              <v-card class="dt-container mt-2">
                <v-data-table
                  :mobile="isMobileOrTablet()"
                  :headers="status != 'done' ? headers : headerWinners"
                  :items="status != 'done' ? guessData : gtpWinners"
                  :loading="loading"
                  class="text-subtitle-1"
                  style="font-family: Saira Condensed, sans-serif"
                >
                  <template v-slot:loading>
                    <v-progress-linear
                      indeterminate
                      color="primary"
                      height="5"
                      class="mt-1 rounded-lg"
                    ></v-progress-linear>
                    <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                      <span>{{ formatDateTimeSeconds(item.createdAt) }}</span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-else
            class="w-full py-2 pl-4 bg-[rgb(18,16,16)] font-weight-bold"
          >
            <v-col
              class="text-black font-weight-bold text-subtitle-1 p-1"
              cols="6"
              style="font-family: Saira Condensed, sans-serif"
            >
              DATE: {{ formatDate(gtbData.createdAt) }}
            </v-col>
            <v-col
              cols="6"
              class="black font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              <span class="font-weight-bold mr-2">Timer:</span>
              <span v-if="!timer.isRunning">
                Time's up</span
              >
              <span v-else>{{ timer.minutes }}M {{ timer.seconds }}S</span>
            </v-col>
            <!-- <v-col cols="12" class=""> -->
            <v-col cols="6" class="black font-weight-bold text-subtitle-1" style="font-family: Saira Condensed, sans-serif"
              >STARTING BALANCE:</v-col
            >
            <v-col cols="6" class="black text-subtitle-1" style="font-family: Saira Condensed, sans-serif"
              >${{ gtbData.startingBalance }}</v-col
            >
            <v-col cols="6" class="black font-weight-bold text-subtitle-1" style="font-family: Saira Condensed, sans-serif">
              STATUS:
            </v-col>
            <v-col cols="6" class="black text-subtitle-1" style="font-family: Saira Condensed, sans-serif">
              <span v-if="gtbData.status == 'active'">
                <v-chip color="blue"> Active </v-chip>
              </span>
              <span v-if="gtbData.status == 'ongoing'">
                <v-chip color="yellow"> Ongoing </v-chip>
              </span>
              <span v-if="gtbData.status == 'started'">
                <v-chip color="green"> Started Playing </v-chip>
              </span>
              <span v-if="gtbData.status == 'done'">
                <v-chip color="gray"> Done </v-chip>
              </span>
              <span v-if="status == 'active'"><v-chip color="blue">Active</v-chip></span>
            </v-col>
            <v-col v-if="gtbData.status == 'done'" cols="6" class="black font-weight-bold text-subtitle-1" style="font-family: Saira Condensed, sans-serif">
              ENDING BALANCE:
            </v-col>
            <v-col v-if="gtbData.status == 'done'" cols="6" class="black text-subtitle-1" style="font-family: Saira Condensed, sans-serif">{{
              gtbData.endingBalance ? "$" + gtbData.endingBalance : ""
            }}</v-col>
            <v-col v-if="gtbData.status == 'done'" cols="6" class="black font-weight-bold text-subtitle-1" style="font-family: Saira Condensed, sans-serif">
              PROFIT:
            </v-col>
            <v-col v-if="gtbData.status == 'done'" cols="6" class="black text-subtitle-1" style="font-family: Saira Condensed, sans-serif">{{
              gtbData.status == "done"
                ? "$" + (gtbData.endingBalance - gtbData.startingBalance)
                : ""
            }}</v-col>
            <!-- <v-col v-if="gtbData.status == 'done'" cols="6" class="black font-weight-bold text-subtitle-1" style="font-family: Saira Condensed, sans-serif">
              BIGGEST WIN:
            </v-col>
            <v-col v-if="gtbData.status == 'done'" cols="6" class="black text-subtitle-1" style="font-family: Saira Condensed, sans-serif">{{
              gtbData.biggestWin ? "$" + gtbData.biggestWin : ""
            }}</v-col> -->
            <v-col v-if="gtbData.status == 'done'" cols="6" class="black font-weight-bold text-subtitle-1" style="font-family: Saira Condensed, sans-serif">
              WINNER:
            </v-col>
            <v-col v-if="gtbData.status == 'done'" cols="6" class="black text-subtitle-1" style="font-family: Saira Condensed, sans-serif">{{
              gtbData.status == "done" ? gtbData?.winners[0]?.username : ""
            }}</v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-3" style="margin-top: auto">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1 text-subtitle-1"
            :disabled="loading"
            text="OK"
            variant="outlined"
            elevation="1"
            @click="closeViewDialog()"
            style="font-family: Saira Condensed, sans-serif"
          ></v-btn>
          <!-- <v-btn
            class="mr-1 text-subtitle-1"
            :loading="loading"
            text="Save"
            variant="elevated"
            elevation="1"
            color="primary"
            @click="validateForm()"
            style="font-family: Saira Condensed, sans-serif"
          ></v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="2500"
      color="secondary"
      location="top"
    >
      <v-icon>mdi-alert-circle</v-icon>
      <span class="ml-2" style="font-family: Saira Condensed, sans-serif">{{
        snackText
      }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import moment from "moment";
import { useTimer } from "vue-timer-hook";

export default defineComponent({
  emits: ["refresh"],
  data() {
    return {
      token: null,
      dialog: false,
      viewDialog: false,
      viewType: null,
      main_title: null,
      sub_title: null,
      action: null,
      loading: false,

      snackbar: false,
      snackText: null,

      gtbData: [],
      id: null,
      gtbId: null,
      slotTitle: null,
      bonusBuyCost: null,
      status: null,
      payoutStatus: null,
      payoutData: [],
      timer: '',
      bonusBuyPayout: null,
      statusTypes: [
        { name: "Ongoing", value: "ongoing" },
        { name: "Done", value: "done" },
        // { name: "Previous", value: "previous" },
      ],
      headers: [
        {
          title: "Username",
          align: "start",
          sortable: false,
          key: "user.username",
        },
        { title: "Guess", align: "end", sortable: false, key: "gtpUserGuess" },
      ],
      headerWinners: [
        { title: "Guessed At", align: "start", sortable: true, key: "createdAt" },
        { title: "Username", align: "start", sortable: false, key: "user" },
        { title: "Guessed", align: "start", sortable: true, key: "guess" },
        { title: "Total Points", align: "end", sortable: true, key: "points" },
      ],
      formRules: {
        required: (value) => !!value || "Required",
        minValue: (value) => (value && value != 0) || "Must be greater than 0",
        maxLength: (value) =>
          (value && value.toString().length <= 10) ||
          "Must be less than or equal to 10 characters",
        noSpacesOnly: (value) =>
          (value && value.trim().length > 0) || "This cannot be just spaces",
        fileSize: (value) => {
          if (value[0]) {
            return (
              value[0].size <= 2 * 1024 * 1024 ||
              "File size must be less than 2MB"
            );
          }
        },
      },
      guessData: [],
      gtpWinners: [],
    };
  },
  methods: {
    async initAdd() {
      this.dialog = true;
      this.main_title = "Add Payout";
      this.sub_title = "Payout information";
      this.action = "add";

      this.getGTB();
    },
    initUpdate(data) {
      this.dialog = true;
      this.main_title = "Edit Payout";
      this.sub_title = "Payout information";
      this.action = "edit";

      this.id = data._id;
      this.slotTitle = data.slotTitle;
      this.bonusBuyCost = data.bonusBuyCost;
      this.status = data.status;
    },
    initUpdateStatus(data) {
      this.dialog = true;
      this.main_title = "Edit Payout";
      this.sub_title = "Payout information";
      this.action = "end";

      this.id = data._id;
      this.slotTitle = data.slotTitle;
      this.bonusBuyCost = data.bonusBuyCost;
      this.status = data.status;
      this.payoutStatus = "end";
    },
    initView(data) {
      this.viewDialog = true;
      this.main_title = "Guess the Payout";
      this.sub_title = "Payout information";
      this.viewType = "gtp";
      this.payoutData = data;
      this.loading = true;

      this.gtpWinners = data.winner
      this.status = data.status;
      axios
        .get(
          process.env.VUE_APP_SERVER_URL +
            "/api/admin/guess-the-payout/guess/user-guesses?gtpId=" +
            data._id,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        )
        .then((res) => {
          this.guessData = res.data.userGuess;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initViewGtb(data) {
      this.viewDialog = true;
      this.viewType = "gtb";
      this.main_title = "Guess the Balance";
      this.sub_title = "Balance information";
      const token = localStorage.getItem("romieinc-admin-accesstoken");
      axios
        .get(
          process.env.VUE_APP_SERVER_URL +
            "/api/admin/guess-the-balance/details/" +
            data._id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        )
        .then((res) => {
          this.gtbData = res.data.gtb;
          if (res.data.gtb.timerTimeStarted) {
            this.timer = useTimer(
              moment(res.data.gtb.timerTimeStarted)
                .utc()
                .add(res.data.gtb.timerDuration ?? 10, "minutes")
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async validateForm() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;

        let formData = {
          id: this.id,
          gtb: this.gtbId,
          slotTitle: this.slotTitle,
          bonusBuyCost: this.bonusBuyCost,
          bonusBuyPayout: this.bonusBuyPayout,
          status: this.status,
        };

        if (this.action == "add") {
          axios
            .post(
              process.env.VUE_APP_SERVER_URL +
                "/api/admin/guess-the-payout/add",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                  "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                },
              }
            )
            .then((res) => {
              if (res.data) {
                this.$emit("refresh");
                this.snackText = "Payout Successfully Added!";
                this.snackbar = true;
                this.$refs.form.reset();
                this.loading = false;
                this.dialog = false;
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        } else if (this.action == "edit") {
          axios
            .put(
              process.env.VUE_APP_SERVER_URL +
                "/api/admin/guess-the-payout/update",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                  "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                },
              }
            )
            .then((res) => {
              if (res.data) {
                this.$emit("refresh");
                this.snackText = "Payout Successfully Updated!";
                this.snackbar = true;
                this.$refs.form.reset();
                this.loading = false;
                this.dialog = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .put(
              process.env.VUE_APP_SERVER_URL +
                "/api/admin/guess-the-payout/update",
              { ...formData, status: "done" },
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                  "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                },
              }
            )
            .then((res) => {
              if(res.data){
                axios
                .post(
                  process.env.VUE_APP_SERVER_URL +
                    "/api/admin/guess-the-payout/guess/calculate-gtp-winner",
                  {
                    id: formData.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${this.token}`,
                      "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                    },
                  }
                )
                .then((res) => {
                  if (res.data) {
                    this.$emit("refresh");
                    this.snackText = "Payout Slot Successfully Ended!";
                    this.snackbar = true;
                    this.$refs.form.reset();
                    this.loading = false;
                    this.dialog = false;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.status === 401) {
                      localStorage.removeItem("vuex");
                      localStorage.removeItem("romieinc-admin-accesstoken");
                      window.location.reload();
                    } else if (
                      error.response.data.message == "No guesses were made"
                    ) {
                      this.$emit("refresh");
                      this.snackText =
                        "No guesses were made. Cannot set winners";
                      this.snackbar = true;
                      this.loading = false;
                      this.dialog = false;
                    } else {
                      console.log(`Error: ${error.response.status}`);
                    }
                  } else if (error.request) {
                    console.log("No response received", error.request);
                  } else {
                    console.log("Error", error.message);
                  }
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    getGTB() {
      const token = localStorage.getItem("romieinc-admin-accesstoken");
      axios
        .get(process.env.VUE_APP_SERVER_URL + "/api/admin/guess-the-balance", {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
          },
        })
        .then((res) => {
          if (res.data) {
            const startedGTB = res.data.gtbs.filter((item) => {
              return item.status == "ongoing" || item.status == "active";
            });
            this.gtbData = startedGTB.map((item) => ({
              name:
                "$" +
                item.startingBalance +
                " (" +
                this.formatDateTime(item.createdAt) +
                ")",
              value: item._id,
            }));
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.removeItem("vuex");
              localStorage.removeItem("romieinc-admin-accesstoken");
              window.location.reload();
            } else {
              console.log(`Error: ${error.response.status}`);
            }
          } else if (error.request) {
            console.log("No response received", error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
    viewDialog(data) {
      this.viewDialog = true;
    },
    closeViewDialog() {
      this.viewDialog = false;
    },
    closeDialog() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
  mounted() {
    this.token = localStorage.getItem("romieinc-admin-accesstoken");
  },
  watch: {
    timer(timer) {
      if (timer.isExpired.value) {
        this.timer.pause();
        console.warn("Timer is expired");
      }
    },
  },
});
</script>

<style scoped></style>
