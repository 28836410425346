<template>
  <v-container class="toggle animated" fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card elevation="3" color="primary">
          <v-card-title style="font-family: Saira Condensed, sans-serif"
            >TOTAL GTB SESSIONS</v-card-title
          >
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="6">
                <DonutChart
                  ref="DonutChart1"
                  :canvasId="'myChart1'"
                  :chartType="1"
                ></DonutChart>
              </v-col>
              <v-col cols="6" class="text-right">
                <div class="text-h5 font-weight-bold mr-4">{{ totalGtbSessions }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="3" color="primary">
          <v-card-title style="font-family: Saira Condensed, sans-serif"
            >TOTAL GTP SPENT</v-card-title
          >
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="6">
                <DonutChart
                  ref="DonutChart2"
                  :canvasId="'myChart2'"
                  :chartType="2"
                ></DonutChart>
              </v-col>
              <v-col cols="6" class="text-right">
                <div class="text-h5 font-weight-bold mr-4">${{ totalGtpSpent }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card elevation="3" color="primary">
          <v-card-title style="font-family: Saira Condensed, sans-serif"
            >Total Payout</v-card-title
          >
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="6">
                <DonutChart
                  ref="DonutChart3"
                  :canvasId="'myChart3'"
                  :chartType="3"
                ></DonutChart>
              </v-col>
              <v-col cols="6" class="text-right">
                <div class="text-h5 font-weight-bold mr-4">${{ totalPayout }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col cols="12">
        <v-card elevation="3" height="400">
          <v-card-title>Dropped students per school year</v-card-title>
          <v-card-text>
            <BarChart ref="BarChart1" :canvasId="'myChart4'"></BarChart>
        </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import DonutChart from "../Charts/DonutChart.vue";
import axios from "axios";

export default defineComponent({
  name: "Dashboard",
  components: {
    DonutChart,
  },
  data() {
    return {
      data: null,
      balanceList: [],
      totalGtbSessions: 0,
      totalGtb: 0,
      totalGtpSpent: 0,
      totalPayout: 0,
      loading: false,
      payoutData: [],
      token: null,
    };
  },
  methods: {
    init() {
      this.token = localStorage.getItem("romieinc-admin-accesstoken");
      this.getBalanceList();
      this.getAllPayoutData();
    },
    async getBalanceList() {
      try {
        this.loading = true;
        const balanceList = await axios.get(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/guess-the-balance`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        );

        if (balanceList.status !== 200) {
          throw "Error fetching balance list";
        }

        this.balanceList = balanceList.data.gtbs;
        this.totalGtb = balanceList.data.gtbs.length;
        this.totalGtbSessions = this.balanceList.filter((item) => item.status == 'done').length;
        this.totalGtpSpent = this.balanceList.reduce((acc, curr) => {
          return acc + curr.startingBalance;
        }, 0);
        this.$refs.DonutChart1.renderGraph(this.totalGtbSessions, this.totalGtb);
        this.$refs.DonutChart2.renderGraph(this.totalGtpSpent, this.totalGtpSpent);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },

    async getAllPayoutData() {
      try {
        const payoutDataDone = await axios.get(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/guess-the-payout?status=done`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        );

        if (payoutDataDone.status !== 200) {
          throw "Error fetching balance list";
        }
        this.payoutData = payoutDataDone.data.slots;
        this.totalPayout = this.payoutData.reduce((acc, curr) => {
          return acc + curr.bonusBuyPayout;
        }, 0);
        this.$refs.DonutChart3.renderGraph(this.totalPayout, this.totalPayout);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.init();
  },
});
</script>

<style scoped></style>
