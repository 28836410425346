<template>
    <v-container class="toggle animated" fluid>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            variant="outlined"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            density="comfortable"
            solo
            hide-details
            class="rounded-lg"
            color="white"
            style="font-family: Saira Condensed, sans-serif"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-card class="dt-container mt-2">
            <v-data-table
              :mobile="isMobileOrTablet()"
              :headers="headers"
              :items="data"
              :search="search"
              :loading="loading"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              <template v-slot:loading>
                <v-progress-linear
                  indeterminate
                  color="primary"
                  height="5"
                  class="mt-1 rounded-lg"
                ></v-progress-linear>
                <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span>{{ formatDateTime(item.createdAt) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from "axios";
  import { defineComponent } from "vue";

  export default defineComponent({
    data() {
      return {
        data: [],
        search: null,
        loading: false,
        headers: [
          {
            title: "Discord Username",
            align: "start",
            sortable: true,
            key: "displayName",
          },
          {
            title: "Kick",
            align: "start",
            sortable: true,
            key: "kick",
          },
          { 
            title: "Twitter", 
            align: "start", 
            sortable: true, 
            key: "twitter" 
          },
          { 
            title: "Roobet", 
            align: "start", 
            sortable: true, 
            key: "roobet" 
          },
        //   { 
        //     title: "Packdraw", 
        //     align: "start", 
        //     sortable: true, 
        //     key: "packdraw" 
        //   },
          { 
            title: "Chicken.GG", 
            align: "start", 
            sortable: true, 
            key: "chickengg" 
          },
          {
            title: "Created At",
            align: "end",
            sortable: true,
            key: "createdAt",
          },
        ],

      };
    },
    methods: {
      init() {
        const token = localStorage.getItem("romieinc-admin-accesstoken");
        if (token) {
          this.loading = true;
          axios
            .get(
              process.env.VUE_APP_SERVER_URL + "/api/admin/user-profile/all-users",
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                },
              }
            )
            .then((res) => {
              if (res) {
                this.data = res.data.allUser;
                this.loading = false;
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  localStorage.removeItem("vuex");
                  localStorage.removeItem("romieinc-admin-accesstoken");
                  window.location.reload();
                } else {
                  console.log(`Error: ${error.response.status}`);
                }
              } else if (error.request) {
                console.log("No response received", error.request);
              } else {
                console.log("Error", error.message);
              }
            });
        } else {
          localStorage.removeItem("vuex");
          localStorage.removeItem("romieinc-admin-accesstoken");
          window.location.reload();
        }
      },
    },
    mounted() {
      this.init();
    },
  });
  </script>
  
  <style scoped></style>
  