<template>
    <v-container class="toggle animated" fluid>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            variant="outlined"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            density="comfortable"
            solo
            hide-details
            class="rounded-lg"
            color="white"
            style="font-family: Saira Condensed, sans-serif"
          ></v-text-field>
        </v-col>
        <!-- <v-col class="text-right" cols="12" md="2">
            <v-select
              v-model="currentLeaderboardDate"
              @update:modelValue="dateChange()"
              variant="outlined"
              label="Leaderboard Date"
              density="comfortable"
              hide-details
              class="rounded-lg"
              :items="leaderboardDateData"
              item-title="leaderboardDate"
              item-value="_id"
              return-object
              style="font-family: Saira Condensed, sans-serif;"
            ></v-select>
        </v-col> -->
        <v-col class="text-right" cols="12" md="8">
          <v-btn
            @click="showAddDialog()"
            prepend-icon="mdi-plus"
            class="mt-2 rounded-lg"
            color="primary"
            height="40px"
            elevation="2"
            style="font-family: Saira Condensed, sans-serif"
          >
            Add Payout
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card class="dt-container mt-2">
            <v-data-table
              :mobile="isMobileOrTablet()"
              :headers="headers"
              :items="data"
              :search="search"
              :loading="loading"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              <template v-slot:loading>
                <v-progress-linear
                  indeterminate
                  color="primary"
                  height="5"
                  class="mt-1 rounded-lg"
                ></v-progress-linear>
                <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span v-if="item.status == 'active'">
                  <v-chip color="blue"> Active </v-chip>
                </span>
                <span v-if="item.status == 'ongoing'">
                  <v-chip color="orange"> Ongoing </v-chip>
                </span>
                <span v-if="item.status == 'done'">
                  <v-chip color="gray"> Done </v-chip>
                </span>
              </template>
              <template v-slot:[`item.bonusBuyCost`]="{ item }">
                <span>{{
                  item.bonusBuyCost > 0 ? "$" + item.bonusBuyCost : ""
                }}</span>
              </template>
              <template v-slot:[`item.bonusBuyPayout`]="{ item }">
                <span>{{
                  item.bonusBuyPayout > 0 ? "$" + item.bonusBuyPayout : ""
                }}</span>
              </template>
              <template v-slot:[`item.profit`]="{ item }">
                <span>{{ item.profit > 0 ? "$" + item.profit : "" }}</span>
              </template>
              <template v-slot:[`item.gtb`]="{ item }">
                <div
                  class="cursor-pointer"
                  @click="viewGtbDetail(item.gtb)"
                  > ${{ item.gtb.startingBalance  }} - {{ formatDateTime(item.gtb.createdAt) }}</div>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span>{{ formatDateTime(item.createdAt) }}</span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    v-if="item.status != 'active'"
                    @click="viewPayoutDetail(item)"
                    class="me-2"
                    size="small"
                  >
                    mdi-eye
                  </v-icon>
                  <v-icon
                    v-if="item.status == 'ongoing' || item.status == 'active'"
                    @click="showEditDialog(item)"
                    class="me-2"
                    size="small"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-if="item.status == 'ongoing'"
                    @click="endGtp(item)"
                    class="me-2"
                    size="small"
                  >
                    mdi-stop-circle-outline
                  </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="deleteDialog"
      width="450"
      persistent
      style="font-family: Saira Condensed, sans-serif"
    >
      <v-card color="primary" rounded="lg" class="pa-2">
        <v-card-text class="font-weight-bold">
          Are you sure you want to start receiving guesses?
        </v-card-text>
        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="deleteDialog = false"
            :disabled="loadingDelete"
            variant="outlined"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingDelete"
            @click="postItem()"
            color="red"
            variant="elevated"
          >
            Confirm
          </v-btn>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      width="450"
      persistent
      style="font-family: Saira Condensed, sans-serif"
    >
      <v-card color="primary" rounded="lg" class="pa-2">
        <v-card-text class="font-weight-bold">
          Are you sure you want to end this payout?
        </v-card-text>
        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="deleteDialog = false"
            :disabled="loadingDelete"
            variant="outlined"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingDelete"
            @click="finalizeGtp()"
            color="red"
            variant="elevated"
          >
            Confirm
          </v-btn>
        </template>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="2500"
      color="secondary"
      location="top"
    >
      <v-icon>mdi-alert-circle</v-icon>
      <span class="ml-2" style="font-family: Saira Condensed, sans-serif">{{
        snackText
      }}</span>
    </v-snackbar>

    <PayoutDialog ref="PayoutDialog" @refresh="init()" />
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import PayoutDialog from "../Dialogs/PayoutDialog.vue";

export default defineComponent({
  components: {
    PayoutDialog,
  },
  data() {
    return {
      data: [],
      ongoingGtb: [],
      leaderboardDateData: [],
      currentLeaderboardDate: [],
      loadingUpdate: false,
      search: null,
      loading: false,
      headers: [
        {
          title: "Slot Name",
          align: "start",
          sortable: true,
          key: "slotTitle",
        },
        { title: "Parent GTB", align: "start", sortable: true, key: "gtb" },
        { title: "Status", align: "start", sortable: true, key: "status" },
        {
          title: "Bonus Buy Cost",
          align: "start",
          sortable: true,
          key: "bonusBuyCost",
        },
        {
          title: "Payout",
          align: "start",
          sortable: true,
          key: "bonusBuyPayout",
        },
        // {
        //   title: "Profit",
        //   align: "start",
        //   sortable: true,
        //   key: "profit",
        // },
        {
          title: "Winner",
          align: "start",
          sortable: true,
          key: "winner[0].user",
        },
        {
          title: "Created At",
          align: "start",
          sortable: true,
          key: "createdAt",
        },
        { title: "Actions", align: "end", sortable: false, key: "action" },
      ],
      snackbar: false,
      snackText: null,

      deleteDialog: false,
      itemToUpdate: [],
    };
  },
  methods: {
    init() {
      const token = localStorage.getItem("romieinc-admin-accesstoken");
      if (token) {
        this.loading = true;
        axios
          .get(process.env.VUE_APP_SERVER_URL + "/api/admin/guess-the-payout", {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          })
          .then((res) => {
            if (res) {
              this.data = res.data.slots;
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.removeItem("vuex");
                localStorage.removeItem("romieinc-admin-accesstoken");
                window.location.reload();
              } else {
                console.log(`Error: ${error.response.status}`);
              }
            } else if (error.request) {
              console.log("No response received", error.request);
            } else {
              console.log("Error", error.message);
            }
          });
          axios
          .get(
            process.env.VUE_APP_SERVER_URL + "/api/admin/guess-the-balance?status=active",
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
              },
            }
          )
          .then((res) => {
            if (res) {
              this.ongoingGtb = res.data.gtbs;
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.removeItem("vuex");
                localStorage.removeItem("romieinc-admin-accesstoken");
                window.location.reload();
              } else {
                console.log(`Error: ${error.response.status}`);
              }
            } else if (error.request) {
              console.log("No response received", error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      } else {
        localStorage.removeItem("vuex");
        localStorage.removeItem("romieinc-admin-accesstoken");
        window.location.reload();
      }
    },
    showAddDialog() {
      this.$refs.PayoutDialog.initAdd();
    },
    showEditDialog(data) {
      this.$refs.PayoutDialog.initUpdate(data);
    },
    viewGtbDetail(data) {
      this.$refs.PayoutDialog.initViewGtb(data);
    },
    viewPayoutDetail(data) {
      this.$refs.PayoutDialog.initView(data);
    },
    startTimer(data) {
      this.deleteDialog = true;
      this.itemToUpdate = data;
    },
    endGtp(data) {
      this.deleteDialog = true;
      this.itemToUpdate = data;
    },
    finalizeGtp() {
      this.deleteDialog = false;
      const formData = {
         _id: this.itemToUpdate._id,
         bonusBuyPayout: this.itemToUpdate.bonusBuyPayout,
         status: 'done',
      }
      this.$refs.PayoutDialog.initUpdateStatus(formData);
    },
    stopTimer(data) {
      this.deleteDialog = true;
      this.itemToUpdate = data;
      //   this.$refs.PayoutDialog.initUpdate(data);
    },
    updateItem() {
      this.deleteDialog = false;
      this.loadingUpdate = true;
      const token = localStorage.getItem("romieinc-admin-accesstoken");
      axios
        .put(
          process.env.VUE_APP_SERVER_URL +
            "/api/admin/guess-the-payout/" +
            this.itemToUpdate.id +
            "/update",
          {
            status: "stopped",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        )
        .then((res) => {
          if (res) {
            this.loadingUpdate = false;
            this.init();
            this.snackText = "Slot Successfully Updated!";
            this.snackbar = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.removeItem("vuex");
              localStorage.removeItem("romieinc-admin-accesstoken");
              window.location.reload();
            } else {
              console.log(`Error: ${error.response.status}`);
            }
          } else {
            console.log("Error", error.message);
          }
        });
    },
  },
  mounted() {
    this.init();
  },
});
</script>

<style scoped></style>
