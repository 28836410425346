import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import MainView from '../views/MainView.vue'
import AuthView from '../views/AuthView.vue'
import LoginPage from '../components/LoginPage.vue'
import Dashboard from '../components/Parents/Dashboard.vue'
import ErrorNotFound from '../components/Utils/ErrorNotFound.vue';
import GuessTheBalance from '@/components/Parents/GuessTheBalance.vue';
import GuessThePayout from '@/components/Parents/GuessThePayout.vue';
import UserProfiles from '@/components/Parents/UserProfiles.vue';

const routes = [
  {
    path: '/',
    component: AuthView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: '/',
        name: 'login',
        component: LoginPage,
      },
      // {
      //   path: '/register',
      //   name: 'register',
      //   component: RegisterPage,
      // },
    ]
  },

  {
    path: '/admin',
    name: 'admin',
    meta: { routeForSuperAdmin: true },
    component: MainView,
    children: [
      {
        path: '/admin/dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: '/admin/gtb',
        component: GuessTheBalance,
        meta: {
          title: 'Guess The Balance'
        }
      },
      {
        path: '/admin/gtp',
        component: GuessThePayout,
        meta: {
          title: 'Guess The Payout'
        }
      },
      {
        path: '/admin/user-profiles',
        component: UserProfiles,
        meta: {
          title: 'User Profiles'
        }
      }
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: ErrorNotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let user = store.state.user;

  if (to.matched.some(rec => rec.meta.alreadyAuth)) {
      if (user) {
          next('/admin/dashboard');
      } else {
          next();
      }
  } else if (to.matched.some(record => record.meta.routeForSuperAdmin)) {
      if (!user) {
          next('/');
      } else {
          next();
      }
  }
  else {
      next();
  }
});

export default router
