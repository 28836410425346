<template>
  <v-container>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card
        class="rounded-lg"
        style="display: flex; flex-direction: column; height: 100%"
      >
        <v-card-title
          style="
            background-color: #000000 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
          "
        >
          <div>
            <span
              class="font-weight-bold text-h6"
              style="font-family: Saira Condensed, sans-serif"
              >{{ main_title }}</span
            >
            <div
              class="text-caption"
              style="font-family: Saira Condensed, sans-serif"
            >
              {{ sub_title }}
            </div>
          </div>
          <v-btn
            :disabled="loading"
            flat
            icon
            color="primary"
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px)">
          <v-form ref="form" class="mt-4">
            <v-row dense>
              <v-col cols="12" v-if="status == 'ongoing'">
                <span
                  class="text-body"
                  style="font-family: Saira Condensed, sans-serif"
                  >Note: Please update status to "Start Playing" when the timer
                  ends or once you start playing.</span
                >
              </v-col>
              <v-col cols="12" v-if="action === 'add'">
                <v-text-field
                  v-model="startingBalance"
                  :rules="[formRules.required, formRules.minValue]"
                  label="Starting Balance"
                  type="number"
                  variant="outlined"
                  density="comfortable"
                  prepend="$"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="action === 'add'">
                <v-text-field
                  v-model="timerDuration"
                  :rules="[formRules.required, formRules.minValue]"
                  label="Timer Duration"
                  type="number"
                  variant="outlined"
                  density="comfortable"
                  hint="This equivalent to minutes"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                ></v-text-field>
              </v-col>
              <!-- <v-col v-if="action == 'edit'" cols="12" md="6">
                <v-select
                  v-model="status"
                  label="Status"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                  :items="statusTypes"
                  item-title="name"
                  item-value="value"
                ></v-select>
              </v-col> -->
              <v-col v-if="action == 'edit' && status == 'done'" cols="12">
                <v-text-field
                  v-model="endingBalance"
                  :rules="[formRules.required]"
                  label="Ending Balance"
                  type="number"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="action == 'end' && balanceStatus == 'end'"
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="biggestWin"
                  :rules="[formRules.required]"
                  label="Biggest Win"
                  type="number"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  style="font-family: Saira Condensed, sans-serif"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-3" style="margin-top: auto">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1 text-subtitle-1"
            :disabled="loading"
            text="Cancel"
            variant="outlined"
            elevation="1"
            @click="closeDialog()"
            style="font-family: Saira Condensed, sans-serif"
          ></v-btn>
          <v-btn
            class="mr-1 text-subtitle-1"
            :loading="loading"
            text="Save"
            variant="elevated"
            elevation="1"
            color="primary"
            @click="validateForm()"
            style="font-family: Saira Condensed, sans-serif"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewDialog" width="1100" persistent>
      <v-card
        class="rounded-lg"
        style="display: flex; flex-direction: column; height: 100%"
      >
        <v-card-title
          style="
            background-color: #000000 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
          "
        >
          <div>
            <span
              class="font-weight-bold text-h6"
              style="font-family: Saira Condensed, sans-serif"
              >{{ main_title }}</span
            >
            <div
              class="text-caption"
              style="font-family: Saira Condensed, sans-serif"
            >
              {{ sub_title }}
            </div>
          </div>
          <v-btn
            :disabled="loading"
            flat
            icon
            color="primary"
            @click="closeViewDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px)">
          <v-row dense class="w-full">
            <v-col
              cols="12"
              class="text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              <span class="font-weight-bold">Status: </span>
              <span v-if="gtbData.status == 'ongoing'">
                <v-chip color="yellow"> Ongoing </v-chip>
              </span>
              <span v-if="gtbData.status == 'started'">
                <v-chip color="green"> Started Playing </v-chip>
              </span>
              <span v-if="gtbData.status == 'done'">
                <v-chip color="gray"> Done </v-chip>
              </span></v-col
            >
            <v-col
              class="text-black text-subtitle-1 p-1"
              cols="6"
              style="font-family: Saira Condensed, sans-serif"
            >
              <span class="font-weight-bold">Date:</span>
              {{ formatDate(gtbData.createdAt) }}
            </v-col>
            <v-col cols="6" style="font-family: Saira Condensed, sans-serif">
              <span class="font-weight-bold">Timer: </span>
              <span v-if="timer.minutes == 0 && timer.seconds == 0">
                Time's up</span
              >
              <span v-else>{{ timer.minutes }}:{{ timer.seconds }}</span>
            </v-col>
            <!-- <v-col cols="12" class=""> -->
            <v-col
              cols="6"
              class="black font-weight-bold"
              style="font-family: Saira Condensed, sans-serif"
              >Starting Balance:</v-col
            >
            <v-col
              cols="6"
              class="black text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >${{ gtbData.startingBalance }}</v-col
            >
            <!-- </v-col> -->
            <!-- <v-container class="d-flex justify-start ga-4"> -->
            <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Ending Balance:
            </v-col>
            <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >${{ gtbData.endingBalance }}</v-col
            >
            <!-- <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Profit:
            </v-col> -->
            <!-- <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >${{ gtbData.profit }}</v-col
            > -->
            <!-- <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Biggest Win:
            </v-col>
            <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
              >${{ gtbData.biggestWin }}</v-col
            > -->
            <!-- <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Overall Winner/s:
            </v-col>
            <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              {{ gtbData.status == 'done' ? getHighestPointsUsers(gtbData.winners) : '' }}
            </v-col> -->
            <v-col
              v-if="gtbData.status == 'done'"
              cols="6"
              class="black font-weight-bold text-subtitle-1"
              style="font-family: Saira Condensed, sans-serif"
            >
              Overall Guess The Balance Result:
            </v-col>

            <v-col cols="12">
              <v-tabs v-model="tab" style="font-family: Saira Condensed, sans-serif">
                <v-tab v-if="gtbData.status == 'done'" value="one">Winners</v-tab>
                <v-tab value="two">All Guesses</v-tab>
              </v-tabs>
            
              <v-tabs-window v-model="tab">
                <v-tabs-window-item value="one">
                    <v-card class="dt-container mt-2">
                      <v-data-table
                        :mobile="isMobileOrTablet()"
                        :headers="headerWinners"
                        :items="gtbData.winners"
                        :loading="loading"
                        class="text-subtitle-1"
                        style="font-family: Saira Condensed, sans-serif"
                      >
                        <template v-slot:loading>
                          <v-progress-linear
                            indeterminate
                            color="primary"
                            height="5"
                            class="mt-1 rounded-lg"
                          ></v-progress-linear>
                          <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                        </template>
                        <template v-slot:[`item.guessedAt`]="{ item }">
                            <span>{{ formatDateTimeSeconds(item.guessedAt) }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                </v-tabs-window-item>
                <v-tabs-window-item value="two">
                    <v-card class="dt-container mt-2">
                      <v-data-table
                        :mobile="isMobileOrTablet()"
                        :headers="headers"
                        :items="guessData"
                        :loading="loading"
                        class="text-subtitle-1"
                        style="font-family: Saira Condensed, sans-serif"
                      >
                        <template v-slot:loading>
                          <v-progress-linear
                            indeterminate
                            color="primary"
                            height="5"
                            class="mt-1 rounded-lg"
                          ></v-progress-linear>
                          <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                        </template>
                        <template v-slot:[`item.guessedAt`]="{ item }">
                            <span>{{ formatDateTimeSeconds(item.guessedAt) }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-col>
            <v-col cols="12" v-if="gtbData.status == 'done'">
              <div
                class="pl-0 text-subtitle-1 font-weight-bold"
                style="font-family: Saira Condensed, sans-serif"
              >
                Overall Guess The Payout Result:
              </div>
              <v-card class="dt-container mt-2">
                <v-data-table
                  :mobile="isMobileOrTablet()"
                  :headers="headerWinnersGtp"
                  :items="gtbData.gtpOverallWinners"
                  :loading="loading"
                  class="text-subtitle-1"
                  style="font-family: Saira Condensed, sans-serif"
                >
                  <template v-slot:loading>
                    <v-progress-linear
                      indeterminate
                      color="primary"
                      height="5"
                      class="mt-1 rounded-lg"
                    ></v-progress-linear>
                    <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
                  </template>
                  <template v-slot:[`item.guessedAt`]="{ item }">
                      <span>{{ formatDateTimeSeconds(item.guessedAt) }}</span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <!-- <v-container
              v-if="gtbData.status !== 'active'"
              class="bg-transparent px-9 py-8 w-full"
            >
              <v-table class="h-auto" style="max-height: 300px">
                <thead class="mb-5">
                  <tr class="black">
                    <th class="w-40 text-h6 font-weight-bold">USER NAME</th>
                    <th class="w-32 text-h6 font-weight-bold">GUESS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in guessData" :key="item._id">
                    <td class="text-subtitle-1">{{ item.user }}</td>
                    <td class="text-subtitle-1">${{ item.guess }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-container> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-3" style="margin-top: auto">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1 text-subtitle-1"
            :disabled="loading"
            text="OK"
            variant="outlined"
            elevation="1"
            @click="closeViewDialog()"
            style="font-family: Saira Condensed, sans-serif"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="2500"
      color="secondary"
      location="top"
    >
      <v-icon>mdi-alert-circle</v-icon>
      <span class="ml-2" style="font-family: Saira Condensed, sans-serif">{{
        snackText
      }}</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { defineComponent } from "vue";
import { useTimer } from "vue-timer-hook";

export default defineComponent({
  emits: ["refresh"],
  data() {
    return {
      token: null,
      dialog: false,
      viewDialog: false,
      main_title: null,
      sub_title: null,
      action: null,
      loading: false,

      snackbar: false,
      snackText: null,
      tab: null,

      gtbData: [],
      id: null,
      startingBalance: null,
      endingBalance: null,
      status: null,
      biggestWin: null,
      bonusBuyPayout: null,
      timerDuration: 0,
      timer: null,
      statusTypes: [
        { name: "Active", value: "active" },
        { name: "Ongoing", value: "ongoing" },
        { name: "Start Playing", value: "started" },
        { name: "Done", value: "done" },
        // { name: "Previous", value: "previous" },
      ],
      headers: [
        {
          title: "Username",
          align: "start",
          sortable: false,
          key: "user.username",
        },
        { title: "Guessed", align: "end", sortable: false, key: "guess" },
      ],
      headerWinners: [
        { title: "Guessed At", align: "start", sortable: true, key: "guessedAt" },
        { title: "Username", align: "start", sortable: false, key: "username" },
        { title: "Guessed", align: "start", sortable: false, key: "guess" },
        { title: "Total Points", align: "start", sortable: true, key: "points" },
        { title: "Guessed Difference", align: "end", sortable: true, key: "guessDifference" },
        
      ],
      headerWinnersGtp: [
        { title: "Guessed At", align: "start", sortable: true, key: "guessedAt" },
        { title: "Username", align: "start", sortable: false, key: "user" },
        { title: "Total Points", align: "end", sortable: true, key: "totalPoints" },
      ],
      formRules: {
        required: (value) => !!value || "Required",
        minValue: (value) => (value && value != 0) || "Must be greater than 0",
        maxLength: (value) =>
          (value && value.toString().length <= 10) ||
          "Must be less than or equal to 10 characters",
        noSpacesOnly: (value) =>
          (value && value.trim().length > 0) || "This cannot be just spaces",
        fileSize: (value) => {
          if (value[0]) {
            return (
              value[0].size <= 2 * 1024 * 1024 ||
              "File size must be less than 2MB"
            );
          }
        },
      },
      guessData: [],
    };
  },
  methods: {
    initAdd() {
      this.dialog = true;
      this.main_title = "Add Balance";
      this.sub_title = "Balance information";
      this.action = "add";
    },
    initUpdate(data) {
      this.dialog = true;
      this.main_title = "Edit Balance";
      this.sub_title = "Balance information";
      this.action = "edit";

      this.id = data._id;
      this.slotTitle = data.slotTitle;
      this.bonusBuyCost = data.bonusBuyCost;
      this.status = data.status;
      this.endingBalance = data.endingBalance;
    },
    initView(data) {
      this.viewDialog = true;
      this.main_title = "Guess the Balance";
      this.sub_title = "Balance information";
      this.gtbData = data;
      if(data.status == 'done'){
        this.tab = 'one'
      }
      this.timer = useTimer(
        moment(data.timerTimeStarted)
          .utc()
          .add(data.timerDuration ?? 10, "minutes")
      );
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_SERVER_URL +
            "/api/admin/guess-the-balance/guesses?gtbId=" +
            data._id,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        )
        .then((res) => {
          this.guessData = res.data.gtbGuesses;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async validateForm() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;

        let formData = {
          id: this.id,
          startingBalance: this.startingBalance,
          endingBalance: this.endingBalance,
          biggestWin: this.biggestWin,
          status: this.status,
          timerDuration: this.timerDuration,
        };

        if (this.action == "add") {
          axios
            .post(
              process.env.VUE_APP_SERVER_URL +
                "/api/admin/guess-the-balance/create",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                  "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                },
              }
            )
            .then((res) => {
              if (res.data) {
                this.$emit("refresh");
                this.snackText = "Guess the Balance Details Added!";
                this.snackbar = true;
                this.$refs.form.reset();
                this.loading = false;
                this.dialog = false;
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        } else if (this.action == "edit") {
          axios
            .patch(
              process.env.VUE_APP_SERVER_URL +
                "/api/admin/guess-the-balance/update",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                  "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                },
              }
            )
            .then((res) => {
              if (res.data) {
                if (formData.status == "done") {
                  axios
                    .post(
                      process.env.VUE_APP_SERVER_URL +
                        "/api/admin/guess-the-payout/guess/total-gtp-winners",
                      { gtbId: formData.id },
                      {
                        headers: {
                          Authorization: `Bearer ${this.token}`,
                          "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data) {
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });

                  axios
                    .post(
                      process.env.VUE_APP_SERVER_URL +
                        "/api/admin/guess-the-balance/guesses/set-winners/" +
                        formData.id,
                      {},
                      {
                        headers: {
                          Authorization: `Bearer ${this.token}`,
                          "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data) {
                        this.setGtbBiggestWin();
                        this.$emit("refresh");
                        this.snackText = "Guess the Balance Details Updated!";
                        this.snackbar = true;
                        this.$refs.form.reset();
                        this.loading = false;
                        this.dialog = false;
                      }
                    })
                    .catch((error) => {
                      if (error.response) {
                        if (error.response.status === 401) {
                          localStorage.removeItem("vuex");
                          localStorage.removeItem("romieinc-admin-accesstoken");
                          window.location.reload();
                        } else if (
                          error.response.data.message == "No guesses were made"
                        ) {
                          this.$emit("refresh");
                          this.snackText =
                            "No guesses were made. Cannot set winners";
                          this.snackbar = true;
                          this.loading = false;
                          this.dialog = false;
                        } else {
                          console.log(`Error: ${error.response.status}`);
                        }
                      } else if (error.request) {
                        console.log("No response received", error.request);
                      } else {
                        console.log("Error", error.message);
                      }
                    });
                } else {
                  console.log('here', formData.status);
                  this.$emit("refresh");
                  this.snackText = "Guess the Balance Details Updated!";
                  this.snackbar = true;
                  this.$refs.form.reset();
                  this.loading = false;
                  this.dialog = false;
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .put(
              process.env.VUE_APP_SERVER_URL +
                "/api/admin/guess-the-payout/update",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${this.token}`,
                  "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
                },
              }
            )
            .then((res) => {
              if (res.data) {
                this.$emit("refresh");
                this.snackText = "Guess the Balance Details Updated!";
                this.snackbar = true;
                this.$refs.form.reset();
                this.loading = false;
                this.dialog = false;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    viewDialog(data) {
      this.viewDialog = true;
    },
    closeViewDialog() {
      this.viewDialog = false;
    },
    closeDialog() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    setGtbBiggestWin() {
      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/guess-the-balance/refresh/${this.id}?gtbId=${this.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "x-admin-key": process.env.VUE_APP_X_ADMIN_KEY,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.token = localStorage.getItem("romieinc-admin-accesstoken");
  },
  watch: {
    timer(timer) {
      if (timer.isExpired.value) {
        this.timer.pause();
        console.warn("Timer is expired");
      }
    },
  },
});
</script>

<style scoped></style>
